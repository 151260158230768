var RegExValidation = (function () {
    function RegExValidation() {
    }
    RegExValidation.emailAddress = function (input) {
        return this._emailAddress.test(input);
    };
    ;
    RegExValidation.telephone = function (input) {
        return this._telephone.test(input);
    };
    ;
    RegExValidation.url = function (input) {
        return input.length >= 5 && this._url.test(input);
    };
    ;
    RegExValidation._emailAddress = new RegExp(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i);
    RegExValidation._telephone = new RegExp(/^[0-9\(\)\+][\d \(\)-\.]*(?:[\s]{0,1}(?:[xX]\d+)?){0,1}$/i);
    RegExValidation._url = new RegExp(/^((https?):\/\/)?[a-z0-9-]+(\.[a-z0-9-]+)+([\/?].*)?$/i);
    return RegExValidation;
}());
$.datepicker.setDefaults({
    firstDay: 1,
    dateFormat: "dd/mm/yy",
    hideIfNoPrevNext: true,
    yearRange: "2005:+1"
});
$(function () {
    $(window).on("resize load", function () {
        $("body").toggleClass("layout-fixed", window.innerWidth > 768);
    });
    if (!Modernizr.svg) {
        $("img[src$='.svg']").each(function () {
            var fallback = $(this).data("fallback");
            if (fallback == null) {
                var parent = $(this).parent();
                fallback = parent.data("fallback");
            }
            if (fallback != null) {
                $(this).attr("src", fallback);
            }
        });
    }
    if (Modernizr.input.placeholder) {
        $('.watermark, input[type="text"]:not([placeholder]):not(.watermark)').each(function (i) {
            if ($(this).attr("placeholder") !== undefined)
                return;
            if ($(this).attr("title") === undefined)
                return;
            $(this).attr("placeholder", $(this).attr("title"));
        });
    }
    else {
        $(".watermark").each(function (i) {
            if ($.trim($(this).val()) === ""
                || $(this).val() === $(this).attr("title")) {
                $(this).val($(this).attr("title")).addClass("watermarkon");
            }
            else {
                $(this).removeClass("watermarkon");
            }
            $(this).on("focus", function () {
                if ($(this).val() === $(this).attr("title")) {
                    $(this).val("").removeClass("watermarkon");
                }
            }).on("blur", function () {
                if ($.trim($(this).val()) === "") {
                    $(this).val($(this).attr("title")).addClass("watermarkon");
                }
            });
        });
        $("form:first").on("submit", function () {
            $(".watermark").each(function (i) {
                if ($(this).val() === $(this).attr("title")) {
                    $(this).val("").removeClass("watermarkon");
                }
            });
        });
    }
    if (typeof console === 'undefined') {
        console = new Console();
        console.log = function (message) { };
    }
    if (IsRetina()) {
        var img = $("#UserDetails > a img");
        if (img.length > 0) {
            img.css("height", "30px").attr("src", img.attr("src").replace("s=30", "s=60"));
        }
    }
    $(".js-intl-tel-input").intlTelInput({
        initialCountry: "gb",
        preferredCountries: ["gb"],
        nationalMode: false,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/7.0.2/lib/libphonenumber/build/utils.js"
    });
    CleanUpTabs();
    $("form.auto-validate").validate();
    $(document).on("click", "a#support-link, a.support-link", function (e) {
        console.log("clicked");
        e.preventDefault();
        var url = $(this).data("supporturl");
        var fallbackUrl = $(this).attr("href");
        $.ajax({
            type: "GET",
            url: url
        }).done(function (data) {
            var placeholder = $("#modal-placeholder");
            placeholder.html(data);
            $("input#Referer", placeholder).val(document.referrer || "");
            $("input#ProblematicUrl", placeholder).val(document.URL);
            var controlState = "";
            try {
                controlState = GetPageControlState();
            }
            catch (error) {
                controlState = "Unable to capture control state";
            }
            $("input#ControlData", placeholder).val(controlState);
            $(".modal:first", placeholder).modal({
                backdrop: 'static',
                show: true
            });
            $("#support-modal form", placeholder).validate();
            $("#support-modal form", placeholder).on("submit", function (event) {
                event.preventDefault();
                if (!$(this).valid()) {
                    return false;
                }
                var formData = new FormData($(this)[0]);
                $.ajax({
                    url: '/help/support?handler=modal',
                    type: 'POST',
                    data: formData,
                    async: false,
                    cache: false,
                    contentType: false,
                    processData: false
                }).done(function (result) {
                    $("#support-modal", placeholder).modal("hide");
                    $("#support-modal-submitted", placeholder).modal("show");
                }).fail(function (xhr) {
                    $("#support-modal", placeholder).modal("hide");
                    $("#support-modal-error", placeholder).modal("show");
                });
                return false;
            });
        }).fail(function (xhr) {
            console.log("error");
            console.log(xhr);
        });
    });
    $(document).on("click", "a#wish-link, a.wish-link", function (e) {
        e.preventDefault();
        var modal = "#wish-modal";
        $(modal).modal({
            backdrop: 'static',
            show: true
        });
        $("#Feedback #Wish", modal).val("");
        $("#Feedback input#WishMadeOnUrl", modal).val(document.URL);
        $("#Feedback", modal).parent().find('.alert-info').hide();
        $("#Feedback", modal).parent().find('.alert-success').hide();
        $("#Feedback", modal).parent().find('.alert-danger').hide();
        $("#support-form-submit", modal).show();
        $("#support-form-submit", modal).off("click").on("click", function (event) {
            event.preventDefault();
            var wish = $("#Feedback #Wish", modal);
            if (wish.val() === undefined || wish.val() == '') {
                wish.parent(".form-group").addClass("has-error");
                return;
            }
            else {
                wish.parent(".form-group").removeClass("has-error");
            }
            var controlState = "";
            try {
                controlState = GetAnglePageControlState();
            }
            catch (error) {
                controlState = "Unable to capture control state";
            }
            $.ajax({
                type: "POST",
                url: PortalFeatureFlags.ApiEndpoint2 + "/help/make-a-wish",
                xhrFields: { withCredentials: true },
                contentType: "application/json",
                data: JSON.stringify({ wish: wish.val(), url: encodeURI(window.location.href), controlData: controlState }),
                beforeSend: function () {
                    $("#support-form-submit", modal).prop("disabled", true);
                    $("#Feedback", modal).parent().find('.alert-info').show();
                }
            }).done(function () {
                $("#Feedback", modal).parent().find('.alert-success').show();
            }).fail(function (xhr) {
                $("#Feedback", modal).parent().find('.alert-danger').show();
                ExceptionDialog("Your wish couldn't be submitted at this time - please try again", xhr, null);
            }).always(function () {
                $("#support-form-submit", modal).prop("disabled", false);
                $("#Feedback", modal).parent().find('.alert-info').hide();
                $("#support-form-submit", modal).hide();
            });
            return false;
        });
    });
    $(document).on("click", "a#tfa-link, a.tfa-link", function (e) {
        console.log("clicked");
        e.preventDefault();
        var url = $(this).data("supporturl");
        $.ajax({
            type: "GET",
            url: url
        }).done(function (data) {
            var placeholder = $("#modal-placeholder");
            placeholder.html(data);
            $(".modal:first", placeholder).modal("show");
        }).fail(function (xhr) {
            console.log("error");
            console.log(xhr);
        });
    });
    ActivateAutoRefresh();
    DeactivateAutoRefresh();
    $("#topnav-toggle-aside").on("click", function (e) {
        e.preventDefault();
        var expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        $.cookies.set("angle-aside-collapsed", $("body").hasClass("aside-collapsed-text"), { expires: expiryDate });
    });
    var fixWidthOfAffixedElements = function () {
        $('[data-spy="affix"]').each(function () {
            var offsetTop = $(this).data("offset-top") | 70;
            $(this).css("top", offsetTop)
                .width($(this).parent().width());
        });
    };
    fixWidthOfAffixedElements();
    $(window).on("resize scroll", fixWidthOfAffixedElements);
    $("#SiteMap a").on("mouseenter", function () {
        $(this).stop().animate({
            paddingLeft: "15px"
        }, 300);
    }, function () {
        $(this).stop().animate({
            paddingLeft: "0px"
        }, 300);
    });
    $.ajaxPrefilter(function (opts, originalOpts, jqXHR) {
        if (PortalFeatureFlags.ApiEndpoint.indexOf("https://") == 0
            && opts.url != null && opts.url != undefined
            && opts.url.toLowerCase().startsWith(PortalFeatureFlags.ApiEndpoint)) {
            opts["xhrFields"] = { withCredentials: true };
        }
    });
});
$(function () {
    $(".fullheight").each(function (i) {
        var tallest = 0;
        $(this).each(function () {
            var thisHeight = $(this).height();
            if (thisHeight > tallest) {
                tallest = thisHeight;
            }
        });
        if (tallest > 0)
            $(this).height(tallest);
    });
    $("#siteMessage .close").on("click", function () {
        $("#siteMessage").stop().slideUp(1000);
    });
});
function ActivateAutoRefresh() {
    $("#siteDialog").on("dialogclose", function (event, ui) {
        Portal.Variables.AutoRefresh = true;
    });
    $('#wish-button').on("click", function () {
        Portal.Variables.AutoRefresh = true;
    });
    $('#support-form-submit, #modal-placeholder .modal-footer input').on("click", function () {
        Portal.Variables.AutoRefresh = true;
    });
}
function DeactivateAutoRefresh() {
    $("#siteDialog").on("dialogcreate", function (event, ui) {
        Portal.Variables.AutoRefresh = false;
    });
    $('#wish-input').on("click", function () {
        Portal.Variables.AutoRefresh = false;
    });
    $('#support-link').on("click", function () {
        Portal.Variables.AutoRefresh = false;
    });
}
function ConnectAutoRefreshToCheckbox(checkboxObject) {
    $("#siteDialog").on("dialogcreate", function (event, ui) {
        checkboxObject.prop("checked", true);
        Portal.Variables.AutoRefresh = true;
    });
    $("#siteDialog").on("dialogclose", function (event, ui) {
        checkboxObject.prop("checked", false);
        Portal.Variables.AutoRefresh = false;
    });
    $('#wish-button, #support-form-submit, #support-modal .modal-footer input').on("click", function () {
        checkboxObject.prop("checked", true);
        Portal.Variables.AutoRefresh = true;
    });
    $('#wish-input, #support-link').on("click", function () {
        checkboxObject.prop("checked", false);
        Portal.Variables.AutoRefresh = false;
    });
}
var Portal;
(function (Portal) {
    var Enums = (function () {
        function Enums() {
        }
        Enums.CustomerReturnCheckStatus = {
            AllCorrect: { Name: "All correct", Value: 1 },
            DifferentSerialNumber: { Name: "Different serial number", Value: 2 },
            PoorlyPackaged: { Name: "Poorly packaged", Value: 4 },
            PhysicalDamage: { Name: "Physical damage", Value: 8 }
        };
        Enums.CustomerReturnItemReplacementSource = {
            Stock: { Name: "Stock", Value: 0 },
            Supplier: { Name: "Supplier", Value: 1 }
        };
        Enums.ReasonForReturn = {
            ReportedFaultyItem: { Name: "Reported Faulty Item", Value: 0 },
            BostonError: { Name: "Boston Error", Value: 1 },
            SupplierError: { Name: "Supplier Error", Value: 2 },
            CustomerOrderedInError: { Name: "Customer Ordered In Error", Value: 3 },
            CustomerNoLongerRequired: { Name: "Customer No Longer Required", Value: 4 },
            GoodsIncompatible: { Name: "Goods Incompatible", Value: 5 },
            GoodsReceivedDamaged: { Name: "Goods Received Damaged", Value: 6 },
            SOR: { Name: "SORs", Value: 7 },
            Upgrade: { Name: "Upgrade", Value: 8 },
            ServerFaultyComponent: { Name: "Server With Faulty Subcomponent", Value: 9 },
            IncorrectItemReturned: { Name: "Incorrect Item Returned", Value: 10 },
            FaultySubComponent: { Name: "Faulty Subcomponent", Value: 11 },
            OOWReturn: { Name: "OOW Return", Value: 12 },
            SPARE: { Name: "SPAREs", Value: 13 },
            URGENTRETURN: { Name: "URGENT RETURN", Value: 14 },
            OnSite: { Name: "ON-SITE", Value: 20 }
        };
        return Enums;
    }());
    Portal.Enums = Enums;
})(Portal || (Portal = {}));
function DocTypeName(docType) {
    switch (docType) {
        case 23: return "Quotation";
        case 17: return "Order";
        case 15: return "Delivery Note";
        case 13: return "Invoice";
        case 14: return "Credit Note";
        case 22: return "Purchase Order";
        case 20: return "Goods Receipt PO";
        case 18: return "Invoice";
        case 21: return "Return";
        case 19: return "Credit Note";
        default: return "Unknown";
    }
}
function ParsePluralDocType(docType) {
    switch (docType) {
        case 23: return "Quotations";
        case 17: return "Orders";
        case 15: return "Delivery Notes";
        case 13: return "Invoices";
        case 14: return "Credit Notes";
        case 22: return "Purchase Orders";
        case 20: return "Goods Receipt POs";
        case 18: return "Invoices";
        case 21: return "Returns";
        case 19: return "Credit Notes";
        default: return "Unknown";
    }
}
var Portal;
(function (Portal) {
    var Personalisation = (function () {
        function Personalisation() {
        }
        Personalisation.AddFavourite = function (title, url) {
            $.ajax({
                type: "POST",
                url: PortalFeatureFlags.ApiEndpoint2 + "/personalisation/favourites",
                xhrFields: { withCredentials: true },
                contentType: "application/json",
                data: JSON.stringify({ "title": title, "url": url })
            }).done(function () {
                window.location.reload();
            }).fail(function (xhr) {
                ExceptionDialog("Your favourites could not be updated - please try again.", xhr, null);
            });
        };
        ;
        Personalisation.RemoveFavourite = function (id) {
            $.ajax({
                type: "DELETE",
                url: PortalFeatureFlags.ApiEndpoint2 + "/personalisation/favourites/" + id,
                xhrFields: { withCredentials: true }
            }).done(function () {
                window.location.reload();
            }).fail(function (xhr) {
                ExceptionDialog("Your favourites could not be updated - please try again.", xhr, null);
            });
        };
        ;
        return Personalisation;
    }());
    Portal.Personalisation = Personalisation;
})(Portal || (Portal = {}));
var INACTIVE_USER_TIME_THRESHOLD = 60 * 60 * 1000;
var USER_ACTIVITY_THROTTLER_TIME = 60 * 1000;
var redirectUrl = '/timeout';
var userActivityTimeout = null;
var userActivityThrottlerTimeout = null;
function resetUserActivityTimeout() {
    clearTimeout(userActivityTimeout);
    userActivityTimeout = setTimeout(function () {
        inactiveUserAction();
    }, INACTIVE_USER_TIME_THRESHOLD);
}
function inactiveUserAction() {
    location.href = redirectUrl;
}
function activateActivityTracker() {
    window.addEventListener("mousemove", userActivityThrottler);
    window.addEventListener("scroll", userActivityThrottler);
    window.addEventListener("keydown", userActivityThrottler);
    window.addEventListener("resize", userActivityThrottler);
    window.addEventListener("beforeunload", deactivateActivityTracker);
}
function deactivateActivityTracker() {
    window.removeEventListener("mousemove", userActivityThrottler);
    window.removeEventListener("scroll", userActivityThrottler);
    window.removeEventListener("keydown", userActivityThrottler);
    window.removeEventListener("resize", userActivityThrottler);
    window.removeEventListener("beforeunload", deactivateActivityTracker);
}
function userActivityThrottler() {
    if (!userActivityThrottlerTimeout) {
        userActivityThrottlerTimeout = setTimeout(function () {
            resetUserActivityTimeout();
            clearTimeout(userActivityThrottlerTimeout);
            userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME);
    }
}
if (document.location.pathname !== redirectUrl) {
    activateActivityTracker();
}
(function ($) {
    var defaultOptions = {
        highlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group")
                .addClass('has-error')
                .removeClass('has-success');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group")
                .removeClass('has-error')
                .addClass('has-success');
        },
        errorElement: 'span',
        errorClass: 'label label-danger',
        errorPlacement: function (error, element) {
            if (element.parent('.input-group').length) {
                error.insertAfter(element.parent());
            }
            else {
                error.insertAfter(element);
            }
        }
    };
    $.validator.setDefaults(defaultOptions);
    $.validator.unobtrusive.options = {
        errorClass: 'has-error',
        validClass: 'has-success',
    };
})(jQuery);
function IsEmail(email) {
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}
function isDigit(c) {
    var strAllowed = '1234567890';
    return (strAllowed.indexOf(c) !== -1);
}
var SBOObjectTypes;
(function (SBOObjectTypes) {
    SBOObjectTypes[SBOObjectTypes["GoodsIssue"] = 60] = "GoodsIssue";
    SBOObjectTypes[SBOObjectTypes["GoodsReceipt"] = 59] = "GoodsReceipt";
    SBOObjectTypes[SBOObjectTypes["CustomerReturn"] = 68] = "CustomerReturn";
})(SBOObjectTypes || (SBOObjectTypes = {}));
;
$.unique = function (arr) {
    var arr_new = [];
    var length = arr.length;
    for (var i = 0; i < length; i++) {
        if (arr_new.indexOf(arr[i], 0) < 0) {
            arr_new.push(arr[i]);
        }
    }
    return arr_new;
};
function FindDuplicates(array) {
    var result = [];
    var sortedArray = array.sort();
    for (var index = 0; index < array.length; index++) {
        try {
            if (sortedArray[index] === sortedArray[index + 1]) {
                result.push(sortedArray[index]);
            }
        }
        catch (e) {
        }
    }
    return result;
}
function IsRetina() {
    if (window.matchMedia) {
        var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
        return (mq && mq.matches || (window.devicePixelRatio > 1));
    }
}
function ParseJSONDate(date) {
    try {
        if (date !== undefined && date != null) {
            return eval(date.replace(/\/Date\((.*?)\)\//gi, "new Date($1)"));
        }
    }
    catch (ex) {
        return new Date(date);
    }
    return new Date();
}
function ConvertUKToUniversalDate(dateString) {
    var splitDate = dateString.split('/');
    var day = +splitDate[0].substr(0, 2);
    var month = +splitDate[1].substr(0, 2) - 1;
    var year = +splitDate[2].substr(0, 4);
    if (splitDate[2].substr(5) != null && splitDate[2].substr(5) != undefined && splitDate[2].substr(5).length > 0) {
        var time = splitDate[2].substr(5).split(':');
        var hour = parseInt(time[0]);
        var min = parseInt(time[1]);
        var date = new Date(Date.UTC(year, month, day, hour, min));
        return date;
    }
    else {
        var date = new Date(Date.UTC(year, month, day));
        return date;
    }
}
function ConvertToJSONDate(date) {
    if (date != null) {
        return "\/Date(" + date.getTime() + "-0000)\/";
    }
}
function FormatDate(date) {
    var day = date.getDate() + "";
    var month = date.getMonth() + 1 + "";
    if (day.length === 1) {
        day = "0" + day;
    }
    if (month.length === 1) {
        month = "0" + month;
    }
    return day + "/" + month + "/" + date.getFullYear();
}
function FormatTime(date) {
    var hours = date.getHours() + "";
    var minutes = date.getMinutes() + "";
    if (hours.length === 1) {
        hours = "0" + hours;
    }
    if (minutes.length === 1) {
        minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
}
function FormatDateAndTime(date) {
    var result = FormatDate(date);
    var hours = date.getHours() + "";
    var minutes = date.getMinutes() + "";
    if (hours.length === 1) {
        hours = "0" + hours;
    }
    if (minutes.length === 1) {
        minutes = "0" + minutes;
    }
    return result + " " + hours + ":" + minutes;
}
function GetDateNow() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var ddStr = dd.toString();
    var mmStr = mm.toString();
    if (dd < 10) {
        ddStr = '0' + dd;
    }
    if (mm < 10) {
        mmStr = '0' + mm;
    }
    return dd + "/" + mm + "/" + yyyy;
}
function htmlEncode(value) {
    return $('<div/>').text(value).html();
}
function htmlDecode(value) {
    return $('<div/>').html(value).text();
}
function commafyValue(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
function uncommafyValue(nStr) {
    return String(nStr).replace(/,/g, '');
}
function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS, 'gi');
    var results = regex.exec(window.location.href);
    if (results === null) {
        return "";
    }
    else {
        return decodeURIComponent(results[1].replace(/\+/g, " ").replace(/%u20ac/, "%E2%82%AC"));
    }
}
function URLEncode(str) {
    return encodeURIComponent(escape(str)).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}
$.fn.SetupItemMasterDataAutoComplete = function (includeNonInventory, autoPostBack, allowAll, onSelect) {
    return this.each(function () {
        if (allowAll) {
            var wrapper = $("<div />").addClass("input-group");
            $(this).wrap(wrapper);
        }
        $(this).on("keyup", function () {
            if ($(this).val() === "") {
                $(this).removeClass("bg-danger");
            }
            if ($(this).val() !== "") {
                $(this).addClass("bg-danger");
            }
        });
        $(this).devbridgeAutocomplete({
            lookup: function (query, done) {
                $.ajax({
                    cache: false,
                    url: PortalFeatureFlags.ApiEndpoint + "/items/auto-complete/?includeNonInventory=" + includeNonInventory + "&term=" + encodeURIComponent($.trim(query)),
                    xhrFields: { withCredentials: true },
                    dataType: "json",
                    type: "GET",
                    contentType: "application/json; charset=utf-8",
                    dataFilter: function (data) { return data; }
                }).done(function (data) {
                    var result = {
                        suggestions: $.map(data, function (item) {
                            return {
                                value: item.Description, data: item
                            };
                        })
                    };
                    done(result);
                });
            },
            noCache: false,
            minChars: 3,
            width: 'flex',
            formatResult: function (suggestion, currentValue) {
                return "<span class='imdAutoComplete'><strong>" + suggestion.data.Label + "</strong>" + (suggestion.data.Description ? "<br><span>" + suggestion.data.Description + "</span>" : "") + "</span>";
            },
            onSelect: function (suggestion) {
                if (onSelect !== undefined && onSelect !== null) {
                    onSelect(suggestion.data.Id, suggestion.data);
                }
                $(this).removeClass("bg-danger");
                $(this).val(suggestion.data.Id);
                if (autoPostBack === true && typeof __doPostBack === "function") {
                    $(this.target).find('input').devbridgeAutocomplete("close");
                    setTimeout('__doPostBack("' + $(this).attr("name") + '", "")', 0);
                }
            }
        });
        if (allowAll === true) {
            var willBeAll = $(this).val() == "ALL";
            var showAll = $("<span />").addClass("input-group-btn").append($("<button />")
                .addClass("btn btn-default")
                .attr("id", "toggleAll")
                .addClass(autoPostBack ? "autoPostBack" : "")
                .attr({ "type": "button", "title": (willBeAll ? "Click for a single item" : "Click for all items"), "tooltip": "All items" })
                .append($("<i />").text(willBeAll ? "Single item" : "").addClass(willBeAll ? "" : "glyphicon glyphicon-asterisk")))
                .on("click", function (e) {
                $(this).removeClass("bg-danger");
                e.preventDefault();
                var textBox = $(this).prev("input");
                var willBeAll = textBox.val() != "ALL";
                textBox.prop("readonly", willBeAll);
                textBox.removeClass("watermarkon").val(willBeAll ? "ALL" : "");
                $("i", this)
                    .removeClass("glyphicon glyphicon-asterisk")
                    .addClass(willBeAll ? "" : "glyphicon glyphicon-asterisk");
                if (willBeAll)
                    $("i", this).text("Single item");
                else
                    $("i", this).text("");
                if (willBeAll) {
                    textBox.devbridgeAutocomplete('disable');
                }
                else {
                    textBox.devbridgeAutocomplete('enable');
                }
                $("#toggleAll").attr("title", (willBeAll ? "Click for a single item" : "Click for all items"));
                if (autoPostBack === true && typeof __doPostBack === "function") {
                    textBox.devbridgeAutocomplete("close");
                    setTimeout('__doPostBack("' + textBox.attr("name") + '", "")', 0);
                }
                else {
                    textBox.trigger("focus");
                }
            });
            if ($(this).val() == "ALL") {
                $(this).prop("readonly", true);
            }
            if (willBeAll && ($("i", this).text() == ""))
                $("i", this).text("Single item");
            showAll.insertAfter($(this));
        }
    });
};
$.fn.SetupAllItemMasterDataAutoComplete = function (includeNonInventory, autoPostBack, allowAll, onSelect) {
    return this.each(function () {
        if (allowAll) {
            var wrapper = $("<div />").addClass("input-group");
            $(this).wrap(wrapper);
        }
        $(this).on("keyup", function () {
            if ($(this).val() === "") {
                $(this).removeClass("bg-danger");
            }
            if ($(this).val() !== "") {
                $(this).addClass("bg-danger");
            }
        });
        $(this).devbridgeAutocomplete({
            lookup: function (query, done) {
                $.ajax({
                    cache: false,
                    url: PortalFeatureFlags.ApiEndpoint + "/items/auto-complete/?includeNonInventory=" + includeNonInventory + "&term=!" + encodeURIComponent($.trim(query)),
                    xhrFields: { withCredentials: true },
                    dataType: "json",
                    type: "GET",
                    contentType: "application/json; charset=utf-8",
                    dataFilter: function (data) { return data; }
                }).done(function (data) {
                    var result = {
                        suggestions: $.map(data, function (item) {
                            return {
                                value: item.Description, data: item
                            };
                        })
                    };
                    done(result);
                });
            },
            noCache: false,
            minChars: 3,
            width: 'flex',
            formatResult: function (suggestion, currentValue) {
                return "<span class='imdAutoComplete'><label>" + suggestion.data.Label + "</label>" + (suggestion.data.Description ? "<br><span>" + suggestion.data.Description + "</span>" : "") + "</span>";
            },
            onSelect: function (suggestion) {
                if (onSelect !== undefined && onSelect !== null) {
                    onSelect(suggestion.data.Id, suggestion.data);
                }
                $(this).removeClass("bg-danger");
                $(this).val(suggestion.data.Id);
                if (autoPostBack === true && typeof __doPostBack === "function") {
                    $(this.target).find('input').devbridgeAutocomplete("close");
                    setTimeout('__doPostBack("' + $(this).attr("name") + '", "")', 0);
                }
            }
        });
        if (allowAll === true) {
            var willBeAll = $(this).val() == "ALL";
            var showAll = $("<span />").addClass("input-group-btn").append($("<button />")
                .addClass("btn btn-default")
                .attr("id", "toggleAll")
                .addClass(autoPostBack ? "autoPostBack" : "")
                .attr({ "type": "button", "title": (willBeAll ? "Click for a single item" : "Click for all items"), "tooltip": "All items" })
                .append($("<i />").text(willBeAll ? "Single item" : "").addClass(willBeAll ? "" : "glyphicon glyphicon-asterisk")))
                .on("click", function (e) {
                $(this).removeClass("bg-danger");
                e.preventDefault();
                var textBox = $(this).prev("input");
                var willBeAll = textBox.val() != "ALL";
                textBox.prop("readonly", willBeAll);
                textBox.removeClass("watermarkon").val(willBeAll ? "ALL" : "");
                $("i", this)
                    .removeClass("glyphicon glyphicon-asterisk")
                    .addClass(willBeAll ? "" : "glyphicon glyphicon-asterisk");
                if (willBeAll) {
                    textBox.devbridgeAutocomplete('disable');
                }
                else {
                    textBox.devbridgeAutocomplete('enable');
                }
                if (willBeAll)
                    $("i", this).text("Single item");
                else
                    $("i", this).text("");
                $("#toggleAll").attr("title", (willBeAll ? "Click for a single item" : "Click for all items"));
                if (autoPostBack === true && typeof __doPostBack === "function") {
                    textBox.devbridgeAutocomplete("close");
                    setTimeout('__doPostBack("' + textBox.attr("name") + '", "")', 0);
                }
                else {
                    textBox.trigger("focus");
                }
            });
            if ($(this).val() == "ALL") {
                $(this).prop("readonly", true);
            }
            if (willBeAll && ($("i", this).text() == ""))
                $("i", this).text("Single item");
            showAll.insertAfter($(this));
        }
    });
};
$.fn.SetupPostCodeAutoComplete = function (addressSelected, postCodeButton, findAddressFromPostCodeButton, lookupSuccess, failedCheck, lookupError, searchError) {
    return this.each(function () {
        var thisObject = $(this);
        if (findAddressFromPostCodeButton == null || findAddressFromPostCodeButton == undefined) {
            findAddressFromPostCodeButton = $('#btnFindAddress');
        }
        if (addressSelected == null || addressSelected == undefined) {
            console.log("address selected event is required!");
            return;
        }
        if (lookupSuccess == null || lookupSuccess == undefined) {
            lookupSuccess = function () {
                if ($('#idpc_error_message').is(':visible')) {
                    $('#address .postcode-lines').show();
                    $('#dlgUseManualAddress').show();
                }
            };
        }
        if (failedCheck == null || failedCheck == undefined) {
            failedCheck = function () {
                $('#lookup_field_error').append($('<p />').text("An error occured. Please enter your address in manually."));
                $('#PostCode').val($('#dlgtxtPostCode').val());
                $('#country').val("GB");
                $('#country').trigger("change");
            };
        }
        if (lookupError == null || lookupError == undefined) {
            lookupError = function () {
                $('#lookup_field_error').append($('<p />').text("An error occured. Please enter your address in manually"));
                $('#PostCode').val($('#dlgtxtPostCode').val());
                $('#country').val("GB");
                $('#country').trigger("change");
            };
        }
        if (searchError == null || searchError == undefined) {
            searchError = null;
        }
        thisObject.setupPostcodeLookup({
            api_key: 'ak_hyttq7mgWrkJQnaQCCFAbFVCKZPJG',
            dropdown_class: "form-control",
            check_key: true,
            onFailedCheck: failedCheck,
            onLookupError: lookupError,
            onSearchError: searchError,
            input: postCodeButton,
            button: findAddressFromPostCodeButton,
            onLookupSuccess: lookupSuccess,
            onAddressSelected: addressSelected
        });
    });
};
function SetupDualDatePickers(startSelector, endSelector, withTime) {
    if (withTime === void 0) { withTime = false; }
    $(startSelector + ", " + endSelector)
        .datetimepicker({
        format: (withTime ? "DD MMM YYYY HH:mm" : "DD MMM YYYY"),
        sideBySide: true
    });
    $(startSelector).on("dp.change", function (e) {
        var startDate = $(startSelector).data("DateTimePicker").date();
        var endDate = $(endSelector).data("DateTimePicker").date();
        if (startDate > endDate) {
            $(endSelector).data("DateTimePicker").date(startDate);
        }
    });
    $(endSelector).on("dp.change", function (e) {
        var startDate = $(startSelector).data("DateTimePicker").date();
        var endDate = $(endSelector).data("DateTimePicker").date();
        if (endDate < startDate) {
            $(startSelector).data("DateTimePicker").date(endDate);
        }
    });
}
var isDialogOpen = false;
function ExceptionDialog(message, xhr, onClose) {
    var detail = "No detail provided";
    if (xhr != undefined && xhr != null) {
        var jsonFault = null;
        try {
            jsonFault = JSON.parse(xhr.responseText);
        }
        catch (e) {
            jsonFault = xhr.responseText;
        }
        if (jsonFault != null) {
            if (jsonFault.ExceptionType === null) {
                if (jsonFault.FaultType != null && jsonFault.Message != null) {
                    detail = jsonFault.FaultType + ": " + jsonFault.Message;
                }
                else {
                    console.log(jsonFault);
                    detail = jsonFault;
                }
            }
            else if (jsonFault.ExceptionType != null && jsonFault.ExceptionMessage != null) {
                detail = jsonFault.ExceptionType + ": " + jsonFault.ExceptionMessage;
            }
            else {
                detail = jsonFault.Message;
            }
        }
        else {
            detail = jsonFault;
        }
    }
    if (detail === null || detail === undefined) {
        console.log(xhr);
    }
    DialogWithDetail(message, detail, "An error has occurred", true, onClose);
}
function DialogWithDetail(message, detail, title, closeOnEscape, onClose) {
    if (title === undefined || title === null || title.trim() === "")
        title = document.title;
    if (message === undefined || message === null || typeof message !== "string" || message.length < 1 || message.trim().length < 1)
        message = "No message to display";
    message = message.toString().replace(/\n/g, "<br />");
    var header = title;
    var body = $('<div />').prop('id', 'dlgDetail');
    body.append($("<p />").html(message));
    if (detail !== undefined && detail !== null && detail.trim() !== "") {
        body.append($("<p />").html(detail.toString().replace(/\n/g, "<br />")));
    }
    var footer = $('<div />')
        .append($("<input />").prop("type", "button").addClass("btn btn-default").val("Close").on("click", function () {
        ModalDialogClose();
    }));
    ModalDialog(header, body, footer, null, onClose, false);
}
function ModalDialog(header, body, footer, openEventFunction, closeEventFunction, large) {
    if (large === void 0) { large = false; }
    if (header === undefined || header === null) {
        header = $('<div />')
            .append($('<button />').prop('type', 'button').addClass('close').on('click', function () { ModalDialogClose(); }).val("X").text("X"))
            .text("");
    }
    if (jQuery.type(header) != "object") {
        header = $('<div />')
            .append($('<span />').text(header))
            .append($('<button />').prop('type', 'button').addClass('close').val("X").on('click', function () { ModalDialogClose(); }).text("X"));
    }
    if (body === undefined || body === null) {
        body = $('<div />').text("No content");
    }
    if (footer === undefined || footer === null) {
        footer = $('<div />').append($("<input />").prop("type", "button").addClass("btn btn-default").val("Close").on("click", function () {
            ModalDialogClose();
        }));
    }
    var createModal = function () {
        $('#modal-placeholder').empty();
        $('#modal-placeholder')
            .append($('<div />').addClass('modal-structure'));
        var modal = $('.modal-structure');
        modal.empty();
        if (openEventFunction != undefined || openEventFunction != null) {
            modal.on("shown.bs.modal", openEventFunction);
        }
        modal.prop("tabindex", "-1");
        modal.on('hidden.bs.modal', function () {
            if (closeEventFunction != undefined || closeEventFunction != null) {
                closeEventFunction;
            }
            $(this).data('modal', null);
            isDialogOpen = false;
        });
        modal.addClass("modal fade")
            .append($('<div />').addClass((large ? "modal-dialog modal-lg" : "modal-dialog"))
            .append($("<div />").addClass("modal-content")
            .append($('<div />').addClass("modal-header").append(header))
            .append($('<div />').addClass("modal-body").append(body))
            .append($('<div />').addClass("modal-footer").append(footer))));
        modal.modal({
            backdrop: 'static',
            show: true
        });
    };
    if (!isDialogOpen) {
        createModal();
        isDialogOpen = true;
    }
    else {
        $('.modal-body').append($('<hr />')).append(body);
    }
}
function ModalDialogOnHide(onClose) {
    $('#modal-placeholder div.modal-structure').on('hidden.bs.modal', function () {
        onClose();
    });
    isDialogOpen = false;
}
function ModalDialogClose() {
    $('#modal-placeholder div.modal-structure').on('hidden.bs.modal', function () {
        $('#modal-placeholder div.modal-structure').remove();
        $('.modal-backdrop').remove();
        $(this).data('modal', null);
        isDialogOpen = false;
    });
    $('#modal-placeholder div.modal-structure').modal('hide');
    isDialogOpen = false;
}
function Dialog(message, title, closeOnEscape, width, onClose) {
    if (title === "" || title === undefined)
        title = document.title;
    if (message != null && message != undefined) {
        message = message.replace(/\n/g, "<br />");
    }
    else {
        message = "No message given";
    }
    var body = $('<p />').html(message);
    ModalDialog(title, body, null, null, onClose, false);
}
function ProgressDialog(message, title) {
    ModalDialogClose();
    if (title === "" || title === undefined)
        title = document.title;
    message = message.replace(/\n/g, "<br />");
    Dialog(message, title, true, 800, function () { ModalDialogClose(); });
}
function DestroyDialog(object) {
    if (object === undefined)
        object = $("#siteDialog");
    if ($(object).dialog().dialog("isOpen") === true) {
        $(object).dialog().dialog("destroy");
    }
    $(object).hide();
}
function EmptyAndDestroyDialog(object) {
    if (object === undefined)
        object = $("#siteDialog");
    if ($(object).dialog().dialog("isOpen") === true) {
        $(object).html("").dialog("destroy");
    }
}
function Confirm(message, title, onAccept, onReject) {
    EmptyAndDestroyDialog();
    var footer = $('<div />').append($("<input />").prop("type", "button").addClass("btn btn-default btn-primary")
        .val("Confirm").on("click", function () {
        if (onAccept != null) {
            onAccept();
        }
        ModalDialogClose();
    })).append($("<input />").prop("type", "button").addClass("btn btn-default").val("Close").on("click", function () {
        if (onReject != null) {
            onReject();
        }
        ModalDialogClose();
    }));
    ModalDialog(title, message, footer, null, null, false);
}
function NotesDialog(selector) {
    $(document).on('mouseover mouseout', selector, function (event) {
        if (event.type === 'mouseover') {
            $(this).css('cursor', 'pointer');
        }
        else {
            $(this).css('cursor', 'auto');
        }
    });
    $(document).on('click', selector, function () {
        var notes = $(this).attr('alt');
        if (notes != undefined) {
            notes.replace(/\n/g, "<br />");
        }
        var body = $('<p />').html(notes);
        ModalDialog("Notes", body, null, null, null, false);
    });
}
function InlineHelp(url, title) {
    ModalDialog(title, "<div id='helpContentDiv'>Loading..</div>", null, null, null, true);
    $('#helpContentDiv').load((url + ' .helpContent'));
}
function AddressAutocomplete(onAccept) {
    var container = $('<div />').addClass('row')
        .append($('<div />').addClass('col-xs-5')
        .append($('<div />').addClass('form-group')
        .append($('<label />').text("Postcode:").prop('for', 'dlgtxtPostCode').addClass('control-label'))
        .append($('<input />').prop("id", "dlgtxtPostCode").prop("placeholder", "Post Code").prop("type", "text").addClass('form-control')))
        .append($('<div />').addClass('form-group')
        .append($('<a />').addClass("btn btn-default").attr("id", "btnFindAddress").append($('<span />').text("Find my UK Address"))))
        .append($('<div />').attr({ "id": "lookup_field" }).addClass("form-group")))
        .append($('<div />').addClass('col-xs-6')
        .append($('<p />').attr('id', 'addressExample')));
    var onAddressSelected = function (address) {
        var add2 = $('<span />').text(address.line_1).append($('<br />'))
            .append($('<span />').text(address.line_2)).append($('<br />'))
            .append($('<span />').text(address.line_3)).append($('<br />'))
            .append($('<span />').text(address.post_town)).append($('<br />'))
            .append($('<span />').text(address.county)).append($('<br />'))
            .append($('<span />').text(address.postcode)).append($('<br />'));
        $('#addressExample').empty();
        $('#addressExample').append(add2);
        $('#addressExample').data('address', address);
        $('#country').trigger("change");
        onAccept(address);
    };
    var onOpen = function () {
        $('#lookup_field').SetupPostCodeAutoComplete(onAddressSelected, $('#dlgtxtPostCode'), $('#btnFindAddress'));
    };
    ModalDialog("UK Address Lookup", container, null, onOpen, null, false);
}
(function ($) {
    $.widget("ui.selectable", {
        options: {
            tableClass: "selectable",
            rowSelectClass: "info",
            rowIdSelector: ".selectedId",
            rowHoverClass: "hover",
            multiSelect: false,
            disableSelection: false
        },
        _create: function () {
            var self = this, o = self.options, el = self.element;
            el.addClass(o.tableClass);
            this.rows = el[0].tBodies[0].rows;
            $(this.rows).each(function () {
                $(this).on('click', $.proxy(self, "_handleMouseDown"));
                $(this).on('rowselect', $.proxy(self, "_rowSelectClass"));
                $(this).on('rowdeselect', $.proxy(self, "_rowSelectClass"));
                $(this).on('mouseover', function () { $(this).addClass(o.rowHoverClass); });
                $(this).on('mouseout', function () { $(this).removeClass(o.rowHoverClass); });
                $(this).css('cursor', 'pointer');
            });
            if (o.disableSelection === true) {
                el.disableSelection();
            }
        },
        getFocusedRow: function () {
            return this.rows[this.lastActiveRow];
        },
        isSelected: function (row) {
            return $(row).hasClass(this.options.rowSelectClass);
        },
        _handleMouseDown: function (event) {
            var nodeName = event.target.nodeName.toLowerCase();
            var parentNodeName = $(event.target).parent().prop("tagName").toLowerCase();
            if (nodeName === "a" || nodeName === "input" || nodeName === "button") {
                event.stopPropagation();
                return true;
            }
            if (nodeName === "span" && parentNodeName === "a") {
                return true;
            }
            var table = this;
            if (table.options.multiSelect) {
                table._handleKeyDown(event, event.currentTarget);
            }
            else {
                table._handleSingleSelect(event.currentTarget);
            }
        },
        _handleKeyDown: function (event, row) {
            var rowIndex = row.sectionRowIndex;
            if (event.shiftKey) {
                if (typeof (this.lastActiveRow) === "undefined")
                    this.focusRow(rowIndex);
                this.lockedRow = this.lastActiveRow;
                if (event.ctrlKey) {
                    this.selectRange(this.lastActiveRow, rowIndex, true);
                }
                else {
                    this.selectRange(this.lockedRow, rowIndex, false);
                    this._focusRow(this.lockedRow);
                }
            }
            else {
                this._handleSingleSelect(row);
            }
        },
        _handleSingleSelect: function (row) {
            var rowIndex = row.sectionRowIndex;
            if (this.isSelected(row)) {
                this.deselectRow(rowIndex);
            }
            else {
                this.selectRow(rowIndex, this.options.multiSelect);
            }
        },
        selectRow: function (rowIndex, keepSelections) {
            var row = this.rows[rowIndex];
            if (keepSelections === false)
                this.clearSelections();
            if (row && this.isSelected(row) === false) {
                this._focusRow(rowIndex);
                $(row).trigger('rowselect');
                $(document).trigger('rowchange', this);
            }
        },
        deselectRow: function (rowIndex) {
            var row = this.rows[rowIndex];
            if (row && this.isSelected(row)) {
                this._focusRow(rowIndex);
                $(row).trigger('rowdeselect');
                $(document).trigger('rowchange', this);
            }
        },
        _focusRow: function (rowIndex) {
            this.lastActiveRow = rowIndex;
        },
        _rowSelectClass: function (event) {
            switch (event.type) {
                case 'rowselect':
                    $(event.currentTarget).addClass(this.options.rowSelectClass);
                    break;
                case 'rowdeselect':
                    $(event.currentTarget).removeClass(this.options.rowSelectClass);
                    break;
                default: break;
            }
        },
        selectAll: function () {
            var self = this;
            if (this.options.multiSelect) {
                this.clearSelections();
                $(this.rows).each(function (rowIndex) {
                    if ($(this).is(":hidden") || $(this).hasClass("prevent-selection")) {
                        return;
                    }
                    self.selectRow(rowIndex, true);
                });
            }
        },
        clearSelections: function () {
            var self = this;
            $(this.rows).each(function (rowIndex) {
                self.deselectRow(rowIndex);
            });
            this._focusRow(0);
        },
        selectRange: function (startIndex, endIndex, keepSelections) {
            if (keepSelections === false)
                this.clearSelections();
            if (startIndex <= endIndex) {
                for (var i = startIndex; i <= endIndex; i++) {
                    this.selectRow(i, true);
                }
            }
            else {
                for (var i = startIndex; i >= endIndex; i--) {
                    this.selectRow(i, true);
                }
            }
        },
        destroy: function () {
            var self = this, o = self.options, el = self.element;
            $(this.rows).each(function () {
                $(this).removeClass(o.rowSelectClass).off("click");
            });
            el.removeClass(o.tableClass);
            $.Widget.prototype.destroy.apply(this, arguments);
        },
        selected: function () {
            var self = this, o = self.options, el = self.element, results = [];
            $(this.rows).each(function () {
                if ($(this).hasClass(o.rowSelectClass)) {
                    var val = $(o.rowIdSelector, this).text();
                    results.push(val);
                }
            });
            return results;
        },
        selectedRows: function () {
            var self = this, o = self.options, el = self.element;
            return $("." + o.rowSelectClass, el);
        }
    });
})(jQuery);
function AJAXEndRequestHandlerToggle() {
    $(document).ready(function () {
        $(".expanderPanel").hide();
        $(".expanderTitle").addClass('Closed');
        $(".expanderTitle").on("click", function () {
            $(this).next(".expanderPanel").slideToggle("slow");
            $(this).toggleClass("Open");
        });
    });
}
function AJAXEndRequestHandlerToggleOpened() {
    $(document).ready(function () {
        $(".expandedPanel").show();
        $(".expandedTitle").addClass('Closed').addClass('Open');
        $(".expandedTitle").on("click", function () {
            $(this).next(".expandedPanel").slideToggle("slow");
            $(this).toggleClass("Open");
        });
    });
}
$.fn.maxZIndex = function (opt) {
    var def = { inc: 10, group: "*" };
    $.extend(def, opt);
    var zmax = 0;
    $(def.group).each(function () {
        var cur = parseInt($(this).css('z-index'), 10);
        zmax = cur > zmax ? cur : zmax;
    });
    if (!this.jquery)
        return zmax;
    return this.each(function () {
        zmax += def.inc;
        $(this).css("z-index", zmax + ' !important');
    });
};
function FilterPageList(inputControl, resetControl, listControl) {
    var list = $(listControl);
    $(inputControl).on("keyup", function (e) {
        var filter = $.trim($(this).val());
        var hasHidden = false;
        if (filter === "" || filter === $(this).attr("title") || filter === $(this).attr("placeholder")) {
            $(".page-list-item", list).removeClass("hidden");
        }
        else
            $(".page-list-item", list).each(function () {
                if ($('a', this).text().search(new RegExp(filter, "i")) < 0
                    && $('p', this).text().search(new RegExp(filter, "i")) < 0) {
                    $(this).addClass("hidden");
                    hasHidden = true;
                }
                else {
                    $(this).removeClass("hidden");
                }
            });
        $(resetControl).prop("disabled", !hasHidden);
    });
    $(inputControl).on("keypress", function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            var url = $(".page-list-item:visible:first a:first", list).attr("href");
            if (url)
                window.location.href = url;
        }
    });
    $(inputControl).on("click", function () {
        $(this).trigger("focus").trigger("select");
    });
    $(resetControl).on("click", function () {
        $(inputControl).val("").trigger("focus").trigger("select");
        $(resetControl).prop("disabled", true);
        $(".page-list-item", list).removeClass("hidden");
    });
    $(resetControl).trigger("click");
    try {
        shortcut.add("Ctrl+F", function () {
            $(inputControl).trigger("focus").trigger("select");
        });
    }
    catch (err) {
        Bugsnag.notifyException(err, null, {
            variableInfo: {
                shortcut: shortcut,
                shortcutIsNull: shortcut == null
            }
        }, "error");
    }
}
function RebuildControls(data) {
    $.each(data, function (i) {
        var self = data[i];
        var id = self.id;
        var control = (self.control || "").toLowerCase();
        var type = (self.type || "").toLowerCase();
        var value = self.value;
        if (control == "input" && (type == "radio" || type == "checkbox")) {
            $("#" + id).prop("checked", value);
            return;
        }
        $("#" + id).val(value);
    });
}
function GetPageControlState() {
    var controls = [];
    $("#gbl_content input[type=text], #gbl_content textarea, #gbl_content select").each(function () {
        var self = $(this);
        var id = self.attr("id");
        var val = self.val();
        var control = self.prop("tagName");
        var type = self.attr("type");
        controls.push({ "control": control, "type": type, "id": id, "value": val });
    });
    $("#gbl_content input[type=radio], #gbl_content input[type=checkbox]").each(function () {
        var self = $(this);
        var id = self.attr("id");
        var val = self.prop("checked");
        var control = self.prop("tagName");
        var type = self.attr("type");
        controls.push({ "control": control, "type": type, "id": id, "value": val });
    });
    return JSON.stringify(controls);
}
function GetAnglePageControlState() {
    var controls = [];
    $(".content-wrapper input[type=text], .content-wrapper textarea, .content-wrapper select").each(function () {
        var self = $(this);
        var id = self.attr("id");
        var val = self.val();
        var control = self.prop("tagName");
        var type = self.attr("type");
        controls.push({ "control": control, "type": type, "id": id, "value": val });
    });
    $(".content-wrapper input[type=radio], .content-wrapper input[type=checkbox]").each(function () {
        var self = $(this);
        var id = self.attr("id");
        var val = self.prop("checked");
        var control = self.prop("tagName");
        var type = self.attr("type");
        controls.push({ "control": control, "type": type, "id": id, "value": val });
    });
    return JSON.stringify(controls);
}
$(function () {
    var section = $(".wish-section"), ul = $(".wish-section ul"), input = $("#wish-input"), button = $("#wish-button");
    ul.css("z-index", "0");
    input.attr({ "placeholder": "I wish this page would...", "tabindex": "-1" }).css({ height: "23px", width: "165px" });
    button.attr("tabindex", "-1");
    if (section.length == 0) {
        return;
    }
    var rebuildWish = getParameterByName("rebuild_wish");
    if (rebuildWish !== undefined && rebuildWish != "") {
        var jsonData = JSON.parse(rebuildWish);
        if (jsonData !== undefined) {
            RebuildControls(jsonData);
        }
    }
    button.on("click", function () {
        var wish = input.val();
        if (wish === undefined || wish == '') {
            return;
        }
        button.prop("disabled", true);
        $.ajax({
            type: "POST",
            url: PortalFeatureFlags.ApiEndpoint2 + "/help/make-a-wish",
            xhrFields: { withCredentials: true },
            contentType: "application/json",
            data: JSON.stringify({ wish: wish, url: encodeURI(window.location.href), controlData: GetPageControlState() })
        }).done(function () {
            input.val("").attr("placeholder", "Thank you!");
            button.prop("disabled", false);
        }).fail(function (xhr) {
            button.prop("disabled", false);
            ExceptionDialog("Your wish couldn't be submitted at this time - please try again", xhr, null);
        });
    });
    input.on("keyup", function (e) { if (e.which == 27) {
        $(this).trigger("blur");
    } });
    input.on("focusin", function () {
        $("<div />").attr("class", "wish-background").appendTo($("body")).fadeIn(400);
        ul.css("z-index", "920").animate({ "background-color": "#f5f5f5" }, 400, function () {
            $(this).css({ "border": "1px solid #e6e6e6" });
        });
        input.animate({ height: "85px", width: "300px" }, 400);
    });
    input.on("focusout", function () {
        $(".wish-background").fadeOut(400, function () { $(this).remove(); });
        input.animate({ height: "23px", width: "165px" }, 400);
        ul.animate({ "background-color": "transparent" }, 400, function () {
            $(this).css({ "border": "none", "z-index": "0" });
        });
    });
});
function siteMessageSuccess(message, delay) {
    var style = "success siteWidth";
    if ($(".topnavbar-wrapper").length > 0) {
        style = "success";
    }
    siteMessage(message, style, delay, "");
}
function siteMessageError(message, delay) {
    var style = "error siteWidth";
    if ($(".topnavbar-wrapper").length > 0) {
        style = "danger";
    }
    siteMessage(message, style, delay, "");
}
var notifyAction = (function () {
    function notifyAction() {
    }
    return notifyAction;
}());
function siteMessage(message, style, delay, title, action) {
    if (delay === void 0) { delay = 5000; }
    if ($(".topnavbar-wrapper").length > 0) {
        var settings = {
            timeout: delay,
            title: title,
            message: message.replace(/\n/g, "<br />"),
            layout: 2,
            maxWidth: 500
        };
        if (action !== undefined && action !== null && action.Title != "") {
            settings.message += "<br/><a href=" + action.Url + ">" + action.Title + "</a>";
        }
        console.log(message);
        switch (style) {
            case "danger":
                iziToast.error(settings);
                break;
            case "warning":
                iziToast.warning(settings);
                break;
            case "success":
                iziToast.success(settings);
                break;
            case "info":
            default:
                iziToast.info(settings);
        }
    }
    else {
        if (style === undefined) {
            style = 'siteWidth';
        }
        if ($("div#siteMessage").length >= 1) {
            $("div#siteMessage")
                .stop(true, true)
                .remove();
        }
        $("<div />", { 'class': style, 'id': 'siteMessage', 'html': message })
            .append($("<i />", { 'class': 'icon-cancel' }).on("click", function () {
            $(this).closest("div")
                .stop(true, true).slideUp(800, function () {
                $(this).remove();
            });
        }))
            .hide()
            .prependTo("body")
            .slideDown(800)
            .delay(delay)
            .slideUp(800, function () { $(this).remove(); });
    }
}
$.views.converters("currency", function (value) {
    if (value == null) {
        return '';
    }
    return Globalize.format(value, "n2");
});
function HideRow(row, onComplete) {
    var tdCount = $("td", row).length;
    $(row).find('td')
        .wrapInner("<div style='display: block'/>")
        .parent().find("td > div")
        .slideUp('slow', function () {
        $(this).parent().parent().remove();
        tdCount--;
        if (tdCount === 0 && onComplete !== null && onComplete !== undefined) {
            onComplete();
        }
    });
}
function CleanUpTabs() {
    var tabAnchors = $("ul.ui-tabs-nav li > a:not(.ui-tabs-anchor)");
    if (tabAnchors.length > 0) {
        console.log("Cleaning up " + tabAnchors.length + " tab(s)");
        tabAnchors.addClass("ui-tabs-anchor");
        Bugsnag.notify("CleanUpTabs", "Tabs were cleaned up automatically - please update the HTML of this page");
    }
}
function SetupTabs() {
    var tabs = $("#tabs");
    if (tabs === undefined || tabs.length == 0) {
        return;
    }
    tabs.idTabs(function (id, list, set) {
        $("#tabs > ul > li")
            .removeClass("selected")
            .removeClass("ui-tabs-selected")
            .removeClass("ui-state-active");
        $("#tabs > ul > li a")
            .filter("[href$='" + id + "']")
            .parent("li")
            .addClass("selected")
            .addClass("ui-tabs-selected")
            .addClass("ui-state-active");
        for (var i = 0; i < list.length; i++) {
            $(list[i]).hide();
        }
        $(id).show();
        return false;
    }, { change: false });
    $("#tabs > ul > li")
        .on("mouseenter", function () { $(this).toggleClass("ui-state-hover"); })
        .on("mouseleave", function () { $(this).toggleClass("ui-state-hover"); });
    var id = location.hash;
    if (id) {
        $("#tabs > ul > li a[href$='" + id + "']")
            .trigger("click");
    }
}
